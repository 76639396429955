<template>

    <div class="h-72 rounded-lg bg-filters mt-2 flex flex-col relative">
        <loader :loading="loading" />
        <div class="h-10 flex flex-row justify-between items-center px-4">
            <span class="text-font-gray font-semibold text-xs">Sales</span>
            <i v-if="sales.won_with_next_to_win_objective  >= 100&&isNotNaAndTbd(sales.won_with_next_to_win_objective )" class="mdi mdi-arrow-top-right text-aux"></i>
            <i v-else-if="isNotNaAndTbd(sales.won_with_next_to_win_objective )" class="mdi mdi-arrow-bottom-left text-danger"></i>
        </div>
        <div class="flex-1 flex flex-col justify-center items-center relative">

            <chart-double-gauge :data="[[sales.won_objective,sales.won_with_next_to_win_objective],sales.won_with_next_to_win_with_estimated_objective]" :limit="sales.won_with_next_to_win_with_estimated_objective"/>
            
            <div class="absolute h-32 w-32 rounded-full flex flex-col justify-center items-center">
                
                <span class="text-blue font-semibold text-2xl">
                    {{ sales.won_with_next_to_win_amount | reduceBigNumbers(2) }} €
                </span>

                <div class="h-10 w-full flex">

                    <div class="h-full w-full flex flex-col justify-center items-center">
                        <span class="text-font-gray text-3xs mb-1">vs Obj.</span>
                        <span class="text-xs font-semibold"
                              :class="successOrDangerClass(sales.won_with_next_to_win_objective,100)"><span v-if="sales.won_with_next_to_win_objective">+</span>{{ sales.won_with_next_to_win_objective | numberFormat(2) }}<span v-if="sales.won_with_next_to_win_objective!='N/A'&&sales.won_with_next_to_win_objective!='TBD'">%</span>
                        </span>
                    </div>

                    <div class="h-full w-full flex flex-col justify-center items-center">
                        <span class="text-font-gray text-3xs mb-1">vs Avg.</span>
                        <span class="text-xs font-semibold">
                            {{ sales.average_percentage | numberFormat(0) }}<small class="text-xxs" v-if="isNotNaAndTbd(sales.average_percentage)"> ptos.</small>
                        </span>
                    </div>

                </div>

            </div>
        </div>
        <div class="h-auto flex flex-row mb-2 px-4">
            <div class="h-full w-1/2 flex flex-col justify-start items-start">
                <span class="text-font-gray text-xxs mb-1">Sales Estimated</span>
                <span class="text-yellow font-semibold">
                    {{ sales.won_with_next_to_win_with_estimated_amount | reduceBigNumbers(2) }} €
                </span>
            </div>
            <div class="h-full w-1/2 flex flex-col justify-start items-end">
                <span class="text-font-gray text-xxs mb-1">% Obj</span>
                <span class="font-semibold text-sm"><span v-if="sales.won_with_next_to_win_with_estimated_objective>0">+</span>{{ sales.won_with_next_to_win_with_estimated_objective | numberFormat(2) }}<span v-if="isNotNaAndTbd(sales.won_with_next_to_win_with_estimated_objective)">%</span>
                </span>
            </div>
        </div>
    </div>

</template>

<script>

import { state } from '@/store';
const ChartDoubleGauge = () => import('@/charts/ChartDoubleGaugeSales.vue');


export default {

    components: {
        ChartDoubleGauge
    },
    data() {
        return {
            salesPercent: 0,
            sales: {},
            limit: 0,
            loading: false,
            sales_estimated: false
        }
    },
    methods: {
        preload() {
            this.loading = true;
        },
        load() {
            this.axios.get('pipeline/sales', {params: this.params}).then(response => {
                this.sales = response.data.data
                this.loading = false
            })
        }
    },
    computed: {
        params() {
            let params = state.globalParams
            if (this.$route.params.catId) {
                params = {...params, ...{ sectorAmbiciona: this.$route.params.catId }}
            }
            return params
        },
    },
    watch:{
        params() { this.load() }
    },
    mounted() {
        this.preload();
        if(this.params) {
            this.load();
        }
    }
}

</script>